export enum ImageFileTypeEnum {
  NONE = "NONE",
  DEFAULT = "DEFAULT",
  TEMPLATE = "TEMPLATE",
  EMAIL = "EMAIL",
  SMS = "SMS",
  NOTIFICATION = "NOTIFICATION",
  PROFILE = "PROFILE",
  STORIES = "STORIES",
  ACTIVITIES = "ACTIVITIES",
  QRCODE = "QRCODE",
  OFFERS = "OFFERS",
  MERCHANTSETTINGLOGO = "MERCHANTSETTINGLOGO",
  MERCHANTSETTINGFAVICON = "MERCHANTSETTINGFAVICON",
  MERCHANTSTORELOGO = "MERCHANTSTORELOGO",
  MERCHANTSTOREPICTURE = "MERCHANTSTOREPICTURE",
  BANNER = "BANNER",
  MERCHANTSTOREITEMGALLERY = "MERCHANTSTOREITEMGALLERY",
  MERCHANTIBAN = "MERCHANTIBAN"
}
export enum ImagesExtensionTypesEnum{
  '.image' = '.image',
  ".jpg" = ".jpg",
  ".svg" = ".svg",
  ".gif" = ".gif",
  ".bmp" = ".bmp",
  ".tif" = ".tif",
  ".jpeg" = ".jpeg",
  ".png" = ".png",
  ".tiff" = ".tiff",
  ".webp" = ".webp",
}
export enum FileExtensionTypesEnum {
  // images
  '.image' = ".image",
  ".pdf" = ".pdf",
  ".doc" = ".doc",
  ".docx" = ".docx",
  ".csv" = ".csv",
  ".html" = ".html",
  ".txt" = ".txt",
  ".mp4" = ".mp4",
}
