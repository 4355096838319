import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, concat, forkJoin, map, merge, tap, throwError } from 'rxjs';
import { _convartParams, _cleanObjectOfEmpty } from '../utils/http.utils';
import { MessageService } from 'primeng/api';
import { FileUploadBody, UploadedFileInfo } from '../interfaces/uploadfiles.interfaces';
import { PostApiResponse } from '../interfaces/common-http.interfaces';

@Injectable({ providedIn: 'root' })
export class HttpService {
  private readonly baseUrl: string = environment.publicUrl;
  private readonly mainUrl: string = environment.mainUrl;
  private readonly newBaseUrl: string = environment.newPublicUrl;

  constructor(
    private http: HttpClient,
    private _messageService: MessageService
  ) { }

  getData<Res = any>(route: string, params: any = null, options: CustomApiMethodsCallOptions = { withoutAdminPreUrl: false, msg: '', allowMsg: true }): Observable<Res> {
    const preUrl = options?.withNewApi ? this.newBaseUrl : (options?.withoutAdminPreUrl ? this.mainUrl : this.baseUrl);
    return this.http.get<Res>(preUrl + route, {
      params: _cleanObjectOfEmpty(params),
    }).pipe(
      tap((response: any) => {
        if (!response && response != 0) {
          throw Error('Response is empty');
        } else if (typeof response === 'object' && ('status' in response && 'statusCode' in response) && !response?.status) {
          // console.log('error response', response);
          throw new Error(response?.message || 'Error to get data!');
        }
      }),
      catchError((err) => {
        if (options?.allowMsg) {
          // console.error('err get '+ route + ' : ', err);
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: options?.msg || err?.error?.message || err?.message|| 'Error',
          });
        }
        throw Error(options?.msg || err?.error?.message || err?.message|| 'Error');
      })
    );
  }
  getDatafromAlotOfApi(
    arrRoute: string[],
    params: any = null,
    Type?: string
  ): Observable<any> {
    let Arr$: Observable<any>[] = [];
    arrRoute.forEach((route) => {
      Arr$.push(
        this.http.get<any>(this.baseUrl + route, {
          params: _convartParams(params),
        })
      );
    });
    if (Type == 'concat') {
      return concat(Arr$);
    } else if (Type == 'merge') {
      return merge(Arr$);
    } else {
      return forkJoin(Arr$).pipe(
        map((data: any[]) => {
          let newData: any[] = [];
          data.forEach((value) => {
            newData = [...newData, ...value];
          });
          return newData;
        })
      );
    }
  }

  uploadFile<Res>(body: FileUploadBody, successMsg: string = 'File uploaded successfully', errMsg: string = '', allowMsg: boolean = true): Observable<Res> {
    return this.http.post(this.mainUrl + `upload`, body).pipe(
      tap((response: any) => {
        if (!response) {
          throw new Error('Response is empty');
        }
        if(
          Object.hasOwnProperty.call(response, 'status') &&
          !response?.status
        ){
          throw new Error(response?.message || 'Error while uploading the file');
        }
      }),
      catchError((err) => {
        if (allowMsg) {
          console.log('ss:', err);
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: errMsg || err?.error?.message || 'Error while uploading the file',
          });
        }
        return throwError(() => err);
      }), tap(res => {
        this._messageService.add({ severity: 'success', summary: 'success', detail: successMsg });
      })
    ) as Observable<Res>;
  }

  postData<Res extends PostApiResponse = PostApiResponse>(route: string, body: any, config: {
    errMsg?: string;
    allowErrMsg?: boolean;
    successMsg?: string;
    allowSuccessMsg?: boolean;
    allowAllMsg?: boolean;
    withoutAdminPreUrl?: boolean;
    withNewApi?: boolean;
  } = {
    errMsg: 'failed in process!',
    allowErrMsg: true,
    successMsg: 'successfully done!',
    allowSuccessMsg: true,
    allowAllMsg: false,
    withoutAdminPreUrl: false,
    withNewApi: false
  }): Observable<Res> {
    const preUrl = config?.withNewApi ? this.newBaseUrl : (config?.withoutAdminPreUrl ? this.mainUrl : this.baseUrl);
    return this.http.post<Res>(preUrl + route, body).pipe(
      catchError((err) => {
        if (config?.allowErrMsg || config?.allowAllMsg) {
          this._messageService.add({
            severity: 'error',
            summary: err?.error?.title || 'Error',
            detail: err?.error?.message || err.error.detail || config?.errMsg || err?.message || 'Error',
          });
        }
        return throwError(() => err);
      }), tap((res: Res) => {
        if (!res) {
          throw new Error('Response is empty');
        }
        if (!res?.status && !(res as any)?.id && !res?.data && !Array.isArray(res)) {
          if (config?.allowErrMsg || config?.allowAllMsg) {
            this._messageService.add({
              severity: 'error',
              summary: res?.title || 'Error',
              detail: res?.message || res?.data || config?.errMsg || 'Error',
            });
          }
          throw new Error(res?.message || res?.data || 'Error');
        }

        if ((config?.allowSuccessMsg || config?.allowAllMsg)) {
          this._messageService.add({ severity: 'success', summary: res?.title || config?.successMsg || 'success', detail: res?.message || 'Added Successfully' });
        }
      })
    ) as Observable<Res>;
  }

  putData<Res extends PostApiResponse = PostApiResponse>(route: string, body: any, config = {
    errMsg: 'Failed in process!',
    allowErrMsg: true,
    successMsg: 'Success',
    allowSuccessMsg: false,
    allowAllMsg: false,
    withoutAdminPreUrl: false
  }): Observable<Res> {
    const preUrl = config?.withoutAdminPreUrl ? this.mainUrl : this.baseUrl;
        return this.http.put<Res>(preUrl + route, body).pipe(
      catchError((err) => {
        if (config?.allowErrMsg || config?.allowAllMsg) {
          this._messageService.add({
            severity: 'error',
            summary: err?.error?.title || 'Error',
            detail: err?.error?.message || err.error.detail || config?.errMsg || err?.message || 'Error',
          });
        }
        return throwError(() => err);
      }), tap((res: Res) => {
        if (!res) {
          throw new Error('Response is empty');
        }
        if (!res?.status && !(res as any)?.id && !res?.data) {
          if (config?.allowErrMsg || config?.allowAllMsg) {
            this._messageService.add({
              severity: 'error',
              summary: res?.title || 'Error',
              detail: res?.message || res?.data || config?.errMsg || 'Error',
            })
          }
          throw new Error(res?.message || res?.data || 'Error');
        }

        if ((config?.allowSuccessMsg || config?.allowAllMsg)) {
          this._messageService.add({ severity: 'success', summary: res?.title || config?.successMsg || 'success',  detail: res?.message || 'Updated Successfully'});
        }
      })
    ) as Observable<Res>;
  }


  deleteData<Res extends PostApiResponse = PostApiResponse>(route: string, options: any, config: CustomPostApiMethodsCallOptions = {
    method: 'delete',
    errMsg: 'failed in process of remove!',
    allowErrMsg: true,
    successMsg: 'successfully removed!',
    allowSuccessMsg: true,
    allowAllMsg: false,
    withoutAdminPreUrl: false
  }): Observable<Res> {
    const preUrl = config?.withoutAdminPreUrl ? this.mainUrl : this.baseUrl;
    return this.http?.[config.method || 'delete'](preUrl + route, options).pipe(
      catchError((err:Res|any) => {
        if (config?.allowErrMsg || config?.allowAllMsg) {
          this._messageService.add({
            severity: 'error',
            summary: err?.error?.title || 'Error',
            detail: err?.error?.message || config?.errMsg || err?.message || 'Error',
          });
        }
        return throwError(() => err);
      }), tap((res: any|Res) => {
        if (!res) {
          throw new Error('Response is empty');
        }
        if (!res?.status && !(res as any)?.id && !(res as any)?.data) {
          if (config?.allowErrMsg || config?.allowAllMsg) {
            this._messageService.add({
              severity: 'error',
              summary: res?.title || 'Error',
              detail: res?.message || config?.errMsg || 'Error',
            });
          }
          throw new Error(res?.message || 'Error');
        }

        if ((config?.allowSuccessMsg || config?.allowAllMsg)) {
          this._messageService.add({ severity: 'success', summary: res?.title || config?.successMsg || 'success', detail: res?.message || 'success' });
        }
      })
    ) as Observable<Res>;
  }
 }

export interface CustomApiMethodsCallOptions {
  withoutAdminPreUrl?: boolean;
  withNewApi?: boolean;
  msg?: string;
  allowMsg?: boolean;
  mapFunction?: Function
  insidePropName?: string;
}


export interface CustomPostApiMethodsCallOptions {
  method: 'get' | 'delete';
  errMsg?: string;
  allowErrMsg?: boolean;
  successMsg?: string;
  allowSuccessMsg?: boolean;
  allowAllMsg?: boolean;
  withoutAdminPreUrl?: boolean;
}

export interface UploadedFileResponse {
  statusCode: number;
  title: string;
  message: string;
  status: boolean;
  statuses: null;
  statusList: null;
}
