import { FileExtensionTypesEnum, ImageFileTypeEnum, ImagesExtensionTypesEnum } from "../Enums/UPLOAD_FILES";
import { FileExtensionType, FileImgExtensionType, ImageFileType } from "../types/uploadfiles.types";
import { _indexEnumValue } from "./enums.utils";

export function _getFileTypeIndex(
  key: ImageFileType
): number | 0 {
  return _indexEnumValue(ImageFileTypeEnum, key) || 0;
}

export function _get_Extension_Type_Of_nonImage_Index(
  key: FileExtensionType
): number | null {
  return _indexEnumValue(FileExtensionTypesEnum, key);
}

export function _get_File_Extension_Type_Index(
  keyToCheck: FileImgExtensionType | FileExtensionType
): number | null {
  if ((keyToCheck as FileImgExtensionType) in ImagesExtensionTypesEnum) {
    return 1;
  }
  return _get_Extension_Type_Of_nonImage_Index(keyToCheck as FileExtensionType);
}
