export function indexEnumValue(enumData: any, key: string) {
  return Object.keys(enumData).indexOf(key);
}

export function _indexEnumValue<T extends Record<string, unknown>>(
  enumData: T,
  key: keyof T
): number | null {
  const values = Object.values(enumData);
  const index = values.findIndex((value) => value == enumData[key]);
  return index !== -1 ? index : null;
}
