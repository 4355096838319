import { HttpParams } from '@angular/common/http';
export function _convartParams(params: any): HttpParams {
  const httpParams = new HttpParams();
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key] == null || params[key] == undefined || params[key] == '' || params[key] == 'null' || params[key] == 'undefined' || params[key] == 'NULL' || params[key] == 'UNDEFINED') {
        return;
      } else {
        httpParams.append(key, params?.[key]);
      }
    });
    return httpParams;
  } else {
    return httpParams;
  }
}

export function _cleanObjectOfEmpty(body: any): any {
  const newValue = { ...body };
  try {
    Object.keys(newValue).forEach((key) => {
      if(newValue[key] != 0 && (newValue[key] == null || newValue[key] == undefined || newValue[key] == '' || newValue[key] == 'null' || newValue[key] == 'undefined' || newValue[key] == 'NULL' || newValue[key] == 'UNDEFINED'))
        delete newValue[key];
    });
  } catch (error) {
    console.error(error);
  }
  return newValue;
}
