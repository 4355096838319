import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,

  ]
})
export class CommonLoaderComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() classes: string = 'flex justify-content-center align-items-center h-full';
  @Input() fontSize: string = '8rem';
  @Input() color: string = '#3e515d';
  constructor() { }

  ngOnInit() {
  }

}
