import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator, ValidatorFn, FormControl, FormGroupDirective, ControlContainer, FormGroup } from '@angular/forms';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Base64ImageData, FileUploadBody, FileUploaderApiConfig, UploadedFileInfo } from '../../interfaces/uploadfiles.interfaces';
import { HttpService } from '../../services/http.service';
import { _convertFileToBase64, _convert_File_To_Base64 } from '../../utils/images.utils';
import { _getFileTypeIndex, _get_File_Extension_Type_Index } from '../../utils/uploadfiles.utils';
import { BehaviorSubject, Observable, catchError, of, switchMap, takeUntil, tap, throwError, map, Subscription, Subject, pipe } from 'rxjs';
import { CheckExtensionPipe } from '../../pipes/checkExtension.pipe';
import { ButtonModule } from 'primeng/button';
import { Tooltip, TooltipModule } from 'primeng/tooltip';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-custom-uploader[apiConfig]',
  templateUrl: './custom-uploader.component.html',
  styleUrls: ['./custom-uploader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    CheckExtensionPipe,
    ButtonModule,
    TooltipModule,
    ReplacePipe,
    ProgressBarModule,
    ImageModule
  ],
  inputs: [
    'disabled',
    'multiple',
    'uploadLabel',
    'chooseLabel',
    'mode',
    'maxFileSize',
    'fileLimit',
    'accept',
    'required',
    'submitted',
    'uploadedFiles',
    'apiConfig',
    'uploadFilesTitle',
    'hint',
    'invalidFileSizeMessageSummary',
    'multipleStore',
    'formControlName',
    'formControl',
    'customValidator'
  ],
  outputs: [
    'onSelectFile',
    'afterSuccessUpload',
    'onClear',
    'onRemove',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomUploaderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CustomUploaderComponent,
      multi: true,
    },
  ],
})
export class CustomUploaderComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  // multi selection and upload
  multiple: boolean = false;
  fileLimit: number = 1;
  chooseLabel: string = 'Choose';
  uploadLabel: string = 'Upload';
  cancelLabel: string = 'Cancel';
  mode: 'basic' | 'advanced' = 'advanced';
  maxFileSize: number = 5000000;
  accept: string = 'image/png, image/jpeg, image/jpg';
  required: boolean = false;
  submitted: boolean = false;
  uploadFilesTitle = "Uploaded Files :";
  hint: string = "*Supported format JPEG & PNG (max {maxFileSize}MB)";
  invalidFileSizeMessageSummary: string = "Image should be max {maxFileSize}MB"
  disabled: boolean = false;
  // multi images stored
  multipleStore: boolean = true;
  customValidator: boolean = true;

  formControlName!:string; // this is cancelled
  private uploadRequestSubscription!: Subscription|null;

  set formControl(formControl:FormControl<any>|AbstractControl<any>){
    if(formControl){
      this.referenceControl = formControl;
    }
  }
  referenceControl!: AbstractControl|FormControl;

  @ViewChild('fileUploader') fileUploader!: FileUpload;
  uploadedFiles: File[] = [];
  StoredFiles: BehaviorSubject<UploadedFileInfo[]|UploadedFileInfo|null> = new BehaviorSubject<UploadedFileInfo[]|UploadedFileInfo|null>([]);
  get storedFilesList(): UploadedFileInfo[]|UploadedFileInfo|null{
    return this.StoredFiles.getValue();
  }
  get storedFiles$(): Observable<UploadedFileInfo[]|UploadedFileInfo|null>{
    return this.StoredFiles.asObservable();
  }

  get storedFilesList$(): Observable<UploadedFileInfo[]>{
    return this.StoredFiles.asObservable().pipe(map((data: UploadedFileInfo[]|UploadedFileInfo|null) => {
      if(!data) return [] as UploadedFileInfo[];
      if(Array.isArray(data)) return data as UploadedFileInfo[];
      return [data] as UploadedFileInfo[];
    })) as Observable<UploadedFileInfo[]>;
  }
  apiConfig!: FileUploaderApiConfig;

  //events emmitter
  onSelectFile = new EventEmitter<any>();
  afterSuccessUpload = new EventEmitter<any>();
  onClear = new EventEmitter<any>();
  onRemove = new EventEmitter<any>();

  uploadLoading: boolean = false;

  storedFilesChange$: Observable<UploadedFileInfo[]|UploadedFileInfo|null>;
  clearEvent = new Subject<boolean>();
  get clearEvent$(){
    return this.clearEvent.asObservable();
  }

  uploadEvent : Subject<any> = new Subject<any>();
  uploadEvent$ !: Observable<any>;

  constructor(
    private _http: HttpService,
    private formGroupDirective: FormGroupDirective,
    private controlContainer: ControlContainer,

  ) {
    this.storedFilesChange$ = this.storedFiles$.pipe(tap(files => {
      this.onChange(files);
    }))
  }

  selectFilesHandler(event: { originalEvent: Event, files: File[], currentFiles: File[] }) {
    // console.log('selectFilesHandler:', [event, this.uploadedFiles]);
    this.onTouched();
    console.log("before pass value, event:", event);
    this.uploadedFiles = event?.currentFiles;
    console.log("after pass value, uploadedFiles:", this.uploadedFiles);

    // console.log('selectFilesHandler:', [event, this.uploadedFiles]);
    // if (this.multiple) {
    //   this.onChange(this.uploadedFiles);
    // } else {
    //   this.onChange(this.uploadedFiles[0]);
    // }
  }

  onChange: any = (value:any) => { };
  touched = false;

  /** It's called in the component template when we have a "blur" or "input" event */
  public registerOnTouched(fn: () => void): void {
    // console.log("touched:",fn)
    this.onTouched = fn;
  }
  public onTouched = (): void => {
    this.touched = true;
    // console.log("touched: ==",this.touched)
  };
  writeValue(value: UploadedFileInfo[]|UploadedFileInfo) {
    if (value) {
      if (Array.isArray(value)) {
        if(!value?.length) {
          !this.multipleStore && this.StoredFiles.next(null);
          return;
        };
        if(this.multipleStore){
          value = value.filter(file =>  file?.['fileName'] && file?.['fullLink'] && file?.['id'])
          this.StoredFiles.next(value);
          return;
        }
        value = value.filter(file =>  file?.['fileName'] && file?.['fullLink'] && file?.['id'])
        this.StoredFiles.next(value?.[0] || null);
        return;
      }
      if(value?.['fileName'] && value?.['fullLink'] && value?.['id']){
        if(this.multipleStore) {
          this.StoredFiles.next([value]);
          return;
        }
        this.StoredFiles.next(value);
        return;
      }
      else {
        this.resetUploadedFiles();
      }
    } else {
      this.resetUploadedFiles();
      this.StoredFiles.next(null);
    }
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    Object.keys(control.errors! || {}).forEach((key) => {
      if (key == 'required') {
        this.required = true;
      }
    });
    return null;
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submitted']) {
      if (this.submitted && this.required && this.uploadedFiles.length == 0) {
        // this.messageService.add({
        //   severity: 'error',
        //   summary: `${this._translateService.instant(
        //     this.label
        //   )} ${this._translateService.instant('Shared.IS_REQUIRED')}`,
        //   detail: '',
        // });
      }
    }
    // if (changes['formControlName'] || changes['formControl']) {
    //   console.log("changes:", [changes['formControlName'], changes['formControl'], this.controlContainer.name])
    //   console.log("sdds", (this.controlContainer.control as FormGroup).controls[changes['formControlName'].currentValue])
    // }
  }

  ngOnInit() {
    this.clearEvent$.pipe(
      switchMap((_) => {
        console.log("clearEvent$:", _);
        this.uploadEvent.complete();

        this.uploadLoading = false
        this.onTouched();
        this.uploadedFiles = [];
        this.fileUploader && this.fileUploader?.clearInputElement();
        this.fileUploader && (this.fileUploader.uploading = false);
        return of(_);
      })
    ).subscribe();

    // this.initializeUploadEvent();
    // this.uploadEvent$.subscribe();
  }

  ngOnDestroy() {
    this.uploadEvent.complete();
    this.uploadEvent.unsubscribe();
    this.clearEvent.complete();
    this.clearEvent.unsubscribe();
  }

  private initializeUploadEvent(): void {
    this.uploadEvent.complete();
    this.uploadEvent = new Subject<any>();
    this.uploadEvent$ = this.uploadEvent.asObservable().pipe(
      takeUntil(this.clearEvent),
      switchMap((bodyData) => {
        return this.uploadFile(bodyData).pipe(
          tap(response => {
            this.resetUploadedFiles();
            this.pushStoredFile(response);
            // return throwError(() => new Error('cancelled'));
          }), catchError(err => {
            this.uploadLoading = false;
            this.fileUploader.uploading = false;
            // if (!this.uploadRequestSubscription) {
            //   return throwError(() => new Error('cancelled')); // Exit the function if the request was canceled
            // }
            return throwError(() => new Error(err));
          })
        );
      }
    ));
    this.uploadEvent$.subscribe();
  }

  onClearHandler() {
    // console.log('onClearHandler:', [event, this.uploadedFiles]);
    // console.log("onClearHandler:", this.uploadedFiles);
    // console.log('onClearHandler:', [event, this.uploadedFiles]);
    // this.onChange(this.uploadedFiles);
    this.clearEvent.next(true);
  }

  onRemoveHandler(event: { originalEvent: Event, file: File }) {
    this.onTouched();
    // console.log('onRemoveHandler:', [event, this.uploadedFiles]);
    // this.uploadedFiles  = this.uploadedFiles.filter((file) => file.name != event.file.name);
    // this.onChange(this.uploadedFiles);
  }

  uploadHandler() {
    if(!this.disabled && !this.uploadLoading){
      this.onTouched();
      // _convert_File_To_Base64(this.uploadedFiles?.[0]).then(
      //   (res) => {
      //     res = res as Base64ImageData | null;
      //     const bodyData: FileUploadBody =
      //     {
      //       data: res?.base64 as string,
      //       ExtentionType: _get_File_Extension_Type_Index(this.apiConfig.ExtentionType) as number,
      //       ImageFileType: _getFileTypeIndex(this.apiConfig.ImageFileType)
      //     }
      //     console.log("bodyData:", bodyData);
      //     this.uploadFile(bodyData)
      //   }
      // ).catch(res => this.uploadLoading = false);

      // this.uploadRequestSubscription = _convertFileToBase64(this.uploadedFiles?.[0]).pipe(
      //   catchError(err => {
      //     this.uploadLoading = false;
      //     return throwError(() => new Error(err));
      //   }),
      //   switchMap(
      //     (res) => {
      //       res = res as Base64ImageData | null;
      //       const bodyData: FileUploadBody = {
      //         data: res?.base64 as string,
      //         ExtentionType: _get_File_Extension_Type_Index(this.apiConfig.ExtentionType) as number,
      //         ImageFileType: _getFileTypeIndex(this.apiConfig.ImageFileType)
      //       };
      //       if (!this.uploadRequestSubscription) {
      //         return throwError(() => new Error('cancelled'));
      //       }
      //       return this.uploadFile(bodyData).pipe(
      //         tap(response => {
      //           this.resetUploadedFiles();
      //           this.pushStoredFile(response);
      //           return throwError(() => new Error('cancelled'));
      //         }), catchError(err => {
      //           this.uploadLoading = false;
      //           if (!this.uploadRequestSubscription) {
      //             return throwError(() => new Error('cancelled')); // Exit the function if the request was canceled
      //           }
      //           return throwError(() => new Error(err));
      //         })
      //       );
      //     }
      //   )
      // ).subscribe(res => {
      //   if (!this.uploadRequestSubscription) {
      //     throwError(() => new Error('cancelled'));
      //   }
      // });
      this.fileUploader.uploading = true;
      this.initializeUploadEvent();
      // this.fileUploader.uploading = false;
      _convertFileToBase64(this.uploadedFiles?.[0]).pipe(
        catchError(err => {
          this.uploadLoading = false;
          return throwError(() => new Error(err));
        }),
        tap(
          (res) => {
            res = res as Base64ImageData | null;
            const bodyData: FileUploadBody = {
              data: res?.base64 as string,
              ExtentionType: _get_File_Extension_Type_Index(this.apiConfig.ExtentionType) as number,
              ImageFileType: _getFileTypeIndex(this.apiConfig.ImageFileType)
            };

            this.uploadLoading = true;
          // this.fileUploader.uploading = true;

            this.uploadEvent.next(bodyData);
          }
        ),
        catchError(err => {
          this.uploadLoading = false;
          this.fileUploader.uploading = false;
          return throwError(() => new Error(err));
        }),
      ).subscribe();
      // .subscribe(res => {
      //   if (!this.uploadRequestSubscription) {
      //     throwError(() => new Error('cancelled'));
      //   }
      // });
    }
  }

  uploadFile(bodyData: FileUploadBody): Observable<UploadedFileInfo> {
    return this._http.uploadFile<UploadedFileInfo>(bodyData).pipe(
      catchError(err => {
        this.uploadLoading = false;
        this.fileUploader.uploading = false;
        return throwError(() => new Error(err));
      }),
    );
  }
  resetUploadedFiles(){
    this.uploadedFiles = [];
    this.fileUploader && this.fileUploader?.clear();
    this.fileUploader && this.fileUploader?.clearInputElement();
    this.fileUploader && (this.fileUploader.uploading = false);

    this.uploadLoading = false;

  }

  pushStoredFile(file: UploadedFileInfo){
    if(this.multipleStore){
      if(Array.isArray(this.storedFilesList)){
        const newList = [...(this.storedFilesList||[]),file];
        this.StoredFiles.next(newList);
        return;
      }
      if(this.storedFilesList) {
        const newList = [this.storedFilesList,file]
        this.StoredFiles.next(newList);
        return;
      }

      this.StoredFiles.next([file]);
      return;
    }
    this.StoredFiles.next(file);
  }

  removeStoredFile(file: UploadedFileInfo, index: number){
    this.onTouched();
    if(Array.isArray(this.storedFilesList)){
      // console.log("storedFilesList:",[...this.storedFilesList, index]);
      const oldList = [...this.storedFilesList];
      oldList.splice(index, 1)
      const newList = [...oldList];
      // console.log("newList:", newList);

      this.StoredFiles.next([...newList]);
      // console.log("new storedFilesList:",this.storedFilesList);

      return;
    }
    if(this.storedFilesList && !this.multipleStore) {
      this.StoredFiles.next(null);
      return;
    }

    this.StoredFiles.next((this.multipleStore? [] : null));
    return;
  }
}

