<router-outlet></router-outlet>
<ngx-spinner
  name="routing-spinner"
  bdColor="#fff"
  size="medium"
  color="#ED6B2D"
  z-index="999999999999999999999999999999"
  [fullScreen]="true"
  template="<img src='../assets/loader.gif' style='width: 500px'/>"
  >
</ngx-spinner>

<p-toast></p-toast>
