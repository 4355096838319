import { Component , OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from './../../authentication/Services/auth-service.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],

})
export class LoaderComponent implements OnInit {

  constructor(private router:Router,
    private authService: AuthServiceService) {}

  ngOnInit(): void {
    setTimeout(() => {
      if(!this.authService.isAuthenticated()) {
        this.router.navigate(['/auth']);
      } else {
        this.router.navigate(['pages']);
      }
    }, 1000);
  }

}
