import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path:"auth" , loadChildren:()=>import('./authentication/authentication.module').then(mod=>mod.AuthenticationModule)},
  {path:"pages",loadChildren:()=>import('./pages/pages.module').then(mod=>mod.PagesModule)},
  {path:"/", redirectTo: '/pages'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
