import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-icon[status]',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class StatusIconComponent implements OnInit {
  @Input() status!: any;
  @Input() trueValue = true;
  @Input() falseValue = false;
  @Input() style: any = {
    'font-size': '1rem'
  };
  // @Input() trueIcon:string = ' pi-check-circle ';
  // @Input() falseIcon:string = ' pi-times-circle ';
  constructor() { }

  ngOnInit() {
  }

}
