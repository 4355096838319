import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  authApi = environment.publicUrl;
  tokenExpire:any;
  // const httpOptions = {
  // headers: new HttpHeaders({
  //   'Content-Type':  'application/json',
  //   'Authorization': 'Basic ' + btoa('username:password')
  // })
// };
  // header = {
  //   headers: new HttpHeaders()
  //     .set('appcode', 'BNPL01')
  //     .set('Content-Type', 'application/json')
  //     .set('Authorization',  `Basic Ym5wbHJvb3R1c2VybmFtZTpMOG5HcTdlZEhNSlFnb1pUWkluZXN5NlptZWRNdFpOU0hRN2M3ZDRxYm96cnI=`)
  // }

  // header2 = {
  //   headers: new HttpHeaders()
  //     .set('appcode', 'BNPL01')
  //     .set('Content-Type', 'application/json')
  //     .set('token',  `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoiXCJ7XFxcImFkbWluX3Nlc3Npb25faWRcXFwiOjUyNTcsXFxcInRva2VuXFxcIjpcXFwiY2U2ZGRiNDMyNDAwMTAyNDQwNjM5NmQzNTI3OWZmNzJcXFwifVwiIiwibmJmIjoxNjc2OTU3NTgwLCJleHAiOjE2NzczODk1ODAsImlhdCI6MTY3Njk1NzU4MH0.eetOTWQkEfAkoj6DwUs3FEbUiOJx32TxSZKr7npeLt4`)
  //     .set('Authorization',  `Basic Ym5wbHJvb3R1c2VybmFtZTpMOG5HcTdlZEhNSlFnb1pUWkluZXN5NlptZWRNdFpOU0hRN2M3ZDRxYm96cnI=`)
  // }
  constructor(private http : HttpClient, private router:Router, public jwtHelper:JwtHelperService) { }

   //intiallize
   int(systemData:any){
    return this.http.post(this.authApi+"token/init" ,systemData )
  }

  //sign in
  signIn(userData:any , tempToken:any){
    this.setToken(tempToken);
    return this.http.post(this.authApi+"sign-in" ,userData )
  }

    //--------------------------------------------------------
  //Dealing With Tokens;

  getToken() {
    return localStorage.getItem("token");
  }

  setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  removeToken(): void {
    localStorage.clear()
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    if(token){
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  // handleError(error:any){
  //   return throwError(error);
  // }
}
