export function getBase64(file: File): FileReader {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return reader;
}
import { Base64ImageData } from '../interfaces/uploadfiles.interfaces';

export function _convert_File_To_Base64(files: File[] | File): Promise<Base64ImageData[] | Base64ImageData | null> {
  if (!files) {
    return Promise.reject(null);
  }

  const reader = new FileReader();

  return new Promise<Base64ImageData[] | Base64ImageData | null>((resolve, reject) => {
    if (Array.isArray(files)) {
      const result: Base64ImageData[] = [];
      for (const file of files) {
        reader.readAsDataURL(file as File);
        reader.onload = (event) => {
          // console.log("reader?.result!:", [btoa(reader?.result as string), reader?.result!])
          const fullBase64 = event?.target?.result as string;
          const base64 = fullBase64.replace(/^data:image\/[a-z]+;base64,/, "") as string;
          const data: Base64ImageData = {
            image: fullBase64,
            base64: base64,
            orientation: -1,
            file: file,
          };
          result.push(data);
          if (result.length == files.length) {
            resolve(result as Base64ImageData []);
          }
        };
      }
    } else {
      reader.readAsDataURL(files);
      reader.onload = (event) => {
        // console.log("reader?.result!:", [btoa(reader?.result as string), reader?.result!])
          const fullBase64 = event?.target?.result as string;
          const base64 = fullBase64.replace(/^data:image\/[a-z]+;base64,/, "") as string;
          const data: Base64ImageData = {
            image: fullBase64,
            base64: base64,
          orientation: -1,
          file: files,
        };
        resolve(data as Base64ImageData);
      };
    }
  });
}

import { Observable, from, throwError } from 'rxjs';

export function _convertFileToBase64(files: File[] | File): Observable<Base64ImageData[] | Base64ImageData | null> {
  if (!files) {
    const err = new Error('no files');
    return throwError(() => err);
  }

  const reader = new FileReader();

  return new Observable<Base64ImageData[] | Base64ImageData | null>((observer) => {
    if (Array.isArray(files)) {
      const result: Base64ImageData[] = [];
      let processedCount = 0;

      for (const file of files) {
        reader.readAsDataURL(file as File);
        reader.onload = (event) => {
          const fullBase64 = event?.target?.result as string;
          const base64 = fullBase64.replace(/^data:image\/[a-z]+;base64,/, "") as string;
          const data: Base64ImageData = {
            image: fullBase64,
            base64: base64,
            orientation: -1,
            file: file,
          };
          result.push(data);
          processedCount++;

          if (processedCount === files.length) {
            observer.next(result as Base64ImageData[]);
            observer.complete();
          }
        };
      }
    } else {
      reader.readAsDataURL(files);
      reader.onload = (event) => {
        const fullBase64 = event?.target?.result as string;
        const base64 = fullBase64.replace(/^data:image\/[a-z]+;base64,/, "") as string;
        const data: Base64ImageData = {
          image: fullBase64,
          base64: base64,
          orientation: -1,
          file: files,
        };
        observer.next(data as Base64ImageData);
        observer.complete();
      };
    }
  });
}
